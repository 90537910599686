const projects = [
  {
    id: 0,
    slug: "md-bytes",
    name: "MD Web Technologies",
    projectDescription:
      "Martin makes his work web home at MD Web Technologies, where you can find more about their services, performance standards, and recent work.",
    imageFile: "projects-0",
    repoUrl: "",
    liveUrl: "https://mdbytes.com",
    featured: true,
    stack: [
      "Gatsby.js",
      "React Framework",
      "JavaScript",
      "Apache",
      "Animate.css",
      "Sass",
      "CSS",
      "HTML",
      "Google Analytics",
      "Email.js",
    ],
  },
  {
    id: 1,
    slug: "penguin-tours",
    name: "Penguin Tours",
    projectDescription:
      "The MD Web Technologies standard company website template - demonstrated by Penguin Tours, a fictitious Antarctic service company. In addition to a landing page with a welcome video, the standard website provides our customers with five pages of content; Services, Portfolio, Testimonials, Faq, and Contact.",
    imageFile: "projects-90",
    repoUrl: "https://github.com/martindwyer/PenguinTours",
    liveUrl: "https://penguin-tours.vercel.app",
    featured: true,
    stack: [
      "Next.js",
      "React Framework",
      "JavaScript",
      "Bootstrap",
      "Sass",
      "CSS",
      "HTML",
      "Lightbox",
      "GLightbox",
      "Google Analytics",
      "Email.js",
      "Vercel PaaS",
    ],
  },
  {
    id: 2,
    slug: "wild-things",
    name: "Wild Things Nature Photography",
    projectDescription:
      "Martin and his wife Rose share a passion for nature and wildlife. This website shares galleries from their wildlife adventures.  The site has a regular following of over 1,000 visitors.",
    imageFile: "projects-1",
    repoUrl: "https://github.com/martindwyer/GoWildThings",
    liveUrl: "https://wildthings.mdbytes.us",
    featured: true,
    stack: [
      "React Framework",
      "JavaScript",
      "Bootstrap",
      "Lightbox",
      "GLightbox",
      "WordPress REST API",
      "PHP",
      "Apache",
      "MySQL",
      "Sass",
      "CSS",
      "HTML",
      "Google Analytics",
    ],
  },
  {
    id: 3,
    slug: "primal-strength",
    name: "Primal Strength and Conditioning",
    projectDescription:
      "A website featuring Martin's work in the field of strength and conditioning. The site is actively visited by over 1,000 patrons.",
    imageFile: "projects-2",
    repoUrl: "https://github.com/martindwyer/PrimalStrength",
    liveUrl: "https://primal.mdbytes.us",
    featured: false,
    stack: [
      "React Framework",
      "JavaScript",
      "WordPress REST API",
      "PHP",
      "Apache",
      "MySQL",
      "Sass",
      "CSS",
      "HTML",
    ],
  },
  {
    id: 4,
    slug: "feedback-focus",
    name: "Feedback Focus: Survey Generation, Transmission, Tabulation",
    projectDescription:
      "A web application which guides users through creation of surveys for their clients or propsects, emails the surveys, and tabulates the results as received.",
    imageFile: "projects-3",
    repoUrl: "https://github.com/martindwyer/FeedbackFocus",
    liveUrl: "https://customer-email-response-app.herokuapp.com/",
    featured: false,
    stack: [
      "MERN",
      "MongoDB",
      "Express",
      "Node",
      "React Framework",
      "Redux",
      "JavaScript",
      "Materialize.css",
      "CSS",
      "HTML",
    ],
  },
  {
    id: 5,
    slug: "expense-manager",
    name: "Expense Manager",
    projectDescription:
      "A web application which allows users to set up, track, and schedule expenses. Customizable based on user needs.",
    imageFile: "projects-4",
    repoUrl: "https://github.com/martindwyer/Expensify",
    liveUrl: "https://mdbytes-expensify-app.herokuapp.com/",
    featured: false,
    stack: [
      "React Framework",
      "Redux",
      "JavaScript",
      "Firebase",
      "Node",
      "Express",
      "Jest",
      "Sass",
      "CSS",
      "HTML",
    ],
  },
  {
    id: 6,
    slug: "employment-app",
    name: "Employment Application",
    projectDescription:
      "An interative web app to guide applicants through an employment application.  Customizeable to individual client needs.",
    imageFile: "projects-5",
    repoUrl: "https://github.com/martindwyer/EmploymentApplication",
    liveUrl: "https://careers.mdbytes.us",
    featured: false,
    stack: ["JavaScript", "CSS", "HTML", "Bootstrap Framework"],
  },
]

export default projects
